import React from "react";
import Gallery from "../components/Gallery.js";
import EventItem from "../components/EventItem.js";
import styled from "styled-components";
import Thumbnail from "../components/Thumbnail";
import { ThumbnailContainer } from "../components/Thumbnail";
import { Link } from "react-router-dom";
import TextBlockOben from "../components/TextBlockOben.js";
const ContainerAll = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const Termine = (props) => {
  return (
    <TextBlockOben>
      <h1>Termine</h1>
      <p>
        Wenn Ihr mich und meine Produkte einmal vor Ort kennenlernen möchtet,
        <br />
        dann schaut doch mal vorbei.
      </p>
      <ThumbnailContainer width={320}>
        <Thumbnail title="" src={require("../images/Stand_TL.jpg")} />
      </ThumbnailContainer>
      <div width={280}>
        <h2>Februar 2025</h2>
        <EventItem
          year="Februar 2025"
          days="01.02 - 02.02."
          text="Kunst- und Antikmarkt im Packhaus Tönning, Am Eiderdamm 18"
        />
        <h2>März 2025</h2>
        <EventItem
          year="März 2025"
          days="29.03. - 30.03."
          text="Frühjahrsmarkt im Freilichtmuseum am Kiekeberg, 21224 Rosengarten"
        />
        <h2>Mai 2025</h2>
        <EventItem
          year="Mai 2025"
          days="29.05."
          text="Kunsthandwerkermarkt am Rantzauer See, 25355 Barmstedt"
        />
        <h2>Juni 2025</h2>
        <EventItem
          year="Juni 2025"
          days="08.06."
          text="Schafmarkt Museumshof Lensahn, 23738 Lensahn"
        />
        <h2>Juli 2025</h2>
        <EventItem
          year="Juli 2025"
          days="25.07. - 27.07."
          text="St. Peter-Ording - Nachhaltigkeitsfestival, Strandläuferweg 18, 25826 St.Peter Ording"
        />
        <h2>August 2025</h2>
        <EventItem
          year="August 2025"
          days="09.08. - 10.08."
          text="Kunsthandwerkermarkt im Museum Kunststätte Bossard, Bossardweg 95, 21266 Jesteburg"
        />
        <EventItem
          year="August 2025"
          days="23.08. - 24.08."
          text="Kunsthandwerkermarkt Allmende Wulfsdorf, Bornkampsweg 36, 22926 Ahrensburg"
        />
        <h2>Oktober 2025</h2>
        <EventItem
          year="Oktober 2025"
          days="03.10. - 07.10."
          text="Herbsmarkt im Freilichtmuseum Molfsee, Hamburger Landstraße 97, 24113 Molfsee"
        />
        <EventItem
          year="Oktober 2025"
          days="25.10. - 26.10."
          text="Glinde Kreativ 2025, im Rathaus und Marcellin-Verbe-Haus, Markt 1, 21509 Glinde"
        />
        <h2>November 2025</h2>
        <EventItem
          year="November 2025"
          days="08.11 - 09.11."
          text="Museumsmarkt 2025, Ostpreußisches Landesmuseum mit Deutschbaltischer Abteilung, Heiligengeiststraße 38, 21335 Lüneburg"
        />
        <EventItem
          year="November 2025"
          days="15.11."
          text="St. Martins Markt, Freie Waldorfschule Elmshorn, Adenauerdamm 2, 25337 Elmshorn"
        />
        <EventItem
          year="November 2025"
          days="28.11. - 30.11."
          text="Weihnachtsmarkt im Freilichtmuseum am Kiekeberg, Am Kiekeberg 1, 21224 Rosengarten-Ehestorf"
        />
      </div>
      
    </TextBlockOben>
  );
};

export { Termine };
